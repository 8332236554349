<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" /> 
    <div class="row">
      <div class="col-md-7">
        <h2 class="titulo-links-escolha">Registrar Disciplinas </h2>
      </div>
      <div class="col-md-5" >
        <pm-Button  type="button" @click="this.$router.push({name: 'turmas', params: {pg: this.pg} });"
          icon="pi pi-angle-left"
          label="VOLTAR" title="VOLTAR"  class="p-button-sm p-button-danger btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
        </pm-Button>

        <pm-Button  type="button" @click="irOutraPg(2)"
          icon="pi pi-id-card"
          label="ALUNOS" title="ALUNOS"  class="p-button-sm p-button-info btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
        </pm-Button>

        <pm-Button  type="button" @click="irOutraPg(1)"
          icon="pi pi-id-card"
          label="PROFESSOR" title="PROFESSOR"  class="p-button-sm p-button-info btn-color" style="margin-top: 35px;float: right;">
        </pm-Button>
      </div>
    </div>
    <div class="pm-card">
      <pm-PickList v-model="disciplinas_item"
        @move-to-target="moveToTarget" @move-all-to-target="moveAllToTarget" @move-to-source="moveToSource" @move-all-to-source="moveAllToSource"
        listStyle="height:342px" dataKey="id" id="disciplinaturma">
        <template #sourceheader>
          Disciplinas
        </template>
        <template #targetheader>
          Disciplinas da Turma
        </template>

        <template #movecontrolsstart>

        </template>

        <template #item="slotProps">
          <div >
            <div >
              <div>
                <span class="">{{slotProps.item.nome}}</span>
              </div>
            </div>
          </div>
        </template>
      </pm-PickList>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { DisciplinaEscolar } from "@/class/disciplina.js";
import { Turma } from "@/class/turma.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";

export default defineComponent({
  props: {
    etapa_id:{},
    periodo_id:{},
    turma_id:{},
    escola_id:{},
    turno:{},
    //Se for 0, nao faz nada. Se for 1 adicionar apenas disciplinas e se for 2 adicionar apenas alunos
    tipoDeAcao: {
      default: 0,
    },
    erroturmaeditar:{},
    pg:{}
  },
  data() {
    return {
      turmaInfo: [],
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Turmas'},
      ],
      disciplina:null,
      disciplinas: [],
      disciplinas_item: [],
      tipo_segmento: null,
    };
  },

  methods: {
    irOutraPg(pg){
      if(pg == 0){
       this.$router.push({name: 'turma-add-disciplina', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
      if(pg == 1){
       this.$router.push({name: 'turma-add-Professor', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
      if(pg == 2){
       this.$router.push({name: 'turma-add-aluno', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
    },

    async buscarDisciplinas(){
      this.disciplinas = [];
      this.disciplinas_item = [];
      const data = await DisciplinaEscolar.obtemTodosSomenteTrue2(this.turma_id);
      for (const el of data.data.disciplinas) {
          if(el.id != 1 && el.id != 2){
            const novo = {
              id: el.id,
              nome: el.nome,
              status: el.status
            }
            this.disciplinas.push(novo);
          }
      }
      //this.disciplinas = data.data.disciplinas;


      this.disciplinas_item.push(this.disciplinas);
      this.disciplinas_item.push(data.data.disciplinas_turma);

    },
    async identificarTipoSegmento(){
      const data = await SegmentoEscolar.obtemUm(this.etapa_id);
      this.tipo_segmento = data.data.tipo;
    },
    async turmaConfig(){
       const turma = {
        turma_id: this.turma_id,
        etapa_id: this.etapa_id,
        periodo_id: this.periodo_id,
        escola_id: this.escola_id,
      }

      const resp_turma = await Turma.aturma(turma);
      this.turmaInfo = resp_turma;
      const  tt = [
        {
          label: resp_turma.data[0].escola
        },
        {
          label: resp_turma.data[0].etapa
        },
        {
          label: resp_turma.data[0].periodo
        },
        {
          label: resp_turma.data[0].turma
        },
        {
          label: resp_turma.data[0].turno
        },
      ];

      this.items_bread = tt;
    },

    async addDisciplina(arr){
      const dt = await Turma.addDisciplina(arr);
      setTimeout(function() {
        this.buscarDisciplinas();
      }, 1000);
    },

    async deletarDisciplina(arr){
      const dt = await Turma.deletarDisciplinaDaTurma(arr);
      setTimeout(function() {
        this.buscarDisciplinas();
      }, 1000);
    },

    moveToTarget(event){
      if(event.items.length>0){
        for (let i = 0; i < event.items.length; i++) {
          const arr = {
            turma_id: parseInt(this.turma_id),
            disciplina_id: event.items[i].id
          };
          this.addDisciplina(arr);
        }
      }

    },
    moveAllToTarget(event){
      if(event.items.length>0){
        for (let i = 0; i < event.items.length; i++) {
          const arr = {
            turma_id: parseInt(this.turma_id),
            disciplina_id: event.items[i].id
          };
          this.addDisciplina(arr);
        }
      }
    },
    moveToSource(event){
      if (this.turmaInfo.data[0].ativa == 1) {
        this.$vaToast.init({
            message:  'Não é permitido excluir disciplinas de turmas ativas!',
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3000,
            color: "warning",
            fullWidth: false,
          });
      }
      else if(event.items.length>0){
        for (let i = 0; i < event.items.length; i++) {
          const arr = {
            turma_id: parseInt(this.turma_id),
            disciplina_id: event.items[i].id
          };
          this.deletarDisciplina(arr);
        }
      }
    },

    moveAllToSource(event){
      if (this.turmaInfo.data[0].ativa == 1) {
        this.$vaToast.init({
            message:  'Não é permitido excluir disciplinas de turmas ativas!',
            iconClass: "fa-ban",
            position: "top-right",
            duration: 3000,
            color: "warning",
            fullWidth: false,
          });
      }
      else if(event.items.length>0){
        for (let i = 0; i < event.items.length; i++) {
          const arr = {
            turma_id: parseInt(this.turma_id),
            disciplina_id: event.items[i].id
          };
          this.deletarDisciplina(arr);
        }
      }
    }

  },
  async beforeMount(){
    try {
      await this.turmaConfig();
      await this.identificarTipoSegmento();
      await this.buscarDisciplinas();
    } catch (e) {
      console.log(e)
    }
  }
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>

<style >
 /*#fg .p-picklist-transfer-buttons .p-button:nth-child(2) {
    display:none;
 }*/
 #disciplinaturma .p-picklist-source-controls{
   display:none;
 }
 #disciplinaturma .p-picklist-target-controls{
   display:none;
 }
</style>
